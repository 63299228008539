import styled from "@emotion/styled";

export const TrendingContainer = styled.section`
  padding: 16px 8px;
`;

export const TrendingTitle = styled.h3`
  font-family: "Fruktur";
  color: #524eb7;
  font-size: 24px;
  margin: 0;
`;

export const TrendingProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px auto;
`;

export const ProductTitle = styled.h4`
  color: #000;
  font-size: 16px;
  margin: 8px 0;
`;

export const ProductImage = styled.img`
`;

export const ProductPrice = styled.strong`
  color: #000;
  font-size: 16px;
  font-weight: bold;
`;
