import React, { useState, useEffect, createRef } from "react";
import { Link, navigate } from "gatsby";
import { Brand } from "../Brand";
import { Icon } from "../Icon";
import { ActionsContainer, HeaderContainer, HeaderLine, IconButton } from "./styles";

// import { isAuth } from '../../helpers/general';

// import AddNotification from '../AddNotification';
// import Brand from '../Brand';
// import Container from '../Container';
// import Config from '../../config.json';
// import Drawer from '../Drawer';
// import ExpandedMenu from '../ExpandedMenu';
// import FormInputField from '../FormInputField/FormInputField';
// import Icon from '../Icons/Icon';
// import MiniCart from '../MiniCart';
// import MobileNavigation from '../MobileNavigation';
// import * as styles from './Header.module.css';

export const Header = () => {
  const [showMiniCart, setShowMiniCart] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const [menu, setMenu] = useState();
  const [activeMenu, setActiveMenu] = useState();

  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");

  const searchRef = createRef();
  const bannerMessage = "Free shipping worldwide";
  const searchSuggestions = [
    "Oversize sweaters",
    "Lama Pajamas",
    "Candles Cinnamon",
  ];

  //   const handleHover = (navObject) => {
  //     if (navObject.category) {
  //       setShowMenu(true);
  //       setMenu(navObject.category);
  //       setShowSearch(false);
  //     } else {
  //       setMenu(undefined);
  //     }
  //     setActiveMenu(navObject.menuLabel);
  //   };

  //   const handleSearch = (e) => {
  //     e.preventDefault();
  //     navigate(`/search?q=${search}`);
  //     setShowSearch(false);
  //   };

  // disable active menu when show menu is hidden
  //   useEffect(() => {
  //     if (showMenu === false) setActiveMenu(false);
  //   }, [showMenu]);

  // hide menu onscroll
  useEffect(() => {
    const onScroll = () => {
      setShowMenu(false);
      setShowSearch(false);
      setActiveMenu(undefined);
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  //listen for show search and delay trigger of focus due to CSS visiblity property
  //   useEffect(() => {
  //     if (showSearch === true) {
  //       setTimeout(() => {
  //         searchRef.current.focus();
  //       }, 250);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [showSearch]);

  return (
    <HeaderContainer>
      {/* <div className={styles.headerMessageContainer}>
        <span>{bannerMessage}</span>
      </div> */}
      {/* <Container size={'large'} spacing={'min'}> */}
      <div>
        {/* header container */}
        <HeaderLine>
          <Brand />
          <ActionsContainer>
            <IconButton
              aria-label="Search"
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <Icon symbol={"search"}></Icon>
            </IconButton>
            {/* <Link
              aria-label="Favorites"
              href="/account/favorites"
              className={`${styles.iconContainer} ${styles.hideOnMobile}`}
            >
              <Icon symbol={'heart'}></Icon>
            </Link> */}
            {/* <Link
              aria-label="Orders"
              href={isAuth() ? '/login' : '/account/orders/'}
              className={`${styles.iconContainer} ${styles.hideOnMobile}`}
            >
              <Icon symbol={'user'}></Icon>
            </Link> */}
            <IconButton
              aria-label="Cart"
              /*className={`${styles.iconButton} ${styles.iconContainer} ${styles.bagIconContainer}`}*/
              onClick={() => {
                setShowMiniCart(true);
              }}
            >
              <Icon symbol={"bag"}></Icon>
              <div /*className={styles.bagNotification}*/>
                <span>1</span>
              </div>
            </IconButton>
            {/* <div className={styles.notificationContainer}>
              <AddNotification openCart={() => setShowMiniCart(true)} />
            </div> */}
          </ActionsContainer>
        </HeaderLine>

        {/* search container */}
        {/* </Container> */}
      </div>

      {/* menu container */}

      {/* minicart container */}
    </HeaderContainer>
  );
};

/**
 * 
        <div
          className={`${styles.searchContainer} ${
            showSearch === true ? styles.show : styles.hide
          }`}
        >
          <h4>What are you looking for?</h4>
          <form className={styles.searchForm} onSubmit={(e) => handleSearch(e)}>
            <FormInputField
              ref={searchRef}
              icon={'arrow'}
              id={'searchInput'}
              value={search}
              placeholder={''}
              type={'text'}
              handleChange={(_, e) => setSearch(e)}
            />
          </form>
          <div className={styles.suggestionContianer}>
            {searchSuggestions.map((suggestion, index) => (
              <p
                role={'presentation'}
                onClick={() => {
                  setShowSearch(false);
                  navigate(`/search?q=${suggestion}`);
                }}
                key={index}
                className={styles.suggestion}
              >
                {suggestion}
              </p>
            ))}
          </div>
          <div
            role={'presentation'}
            onClick={(e) => {
              e.stopPropagation();
              setShowSearch(false);
            }}
            className={styles.backdrop}
          ></div>
        </div>
 */

/**
         * 
      <div
        role={'presentation'}
        onMouseLeave={() => setShowMenu(false)}
        onMouseEnter={() => setShowMenu(true)}
        className={`${styles.menuContainer} ${
          showMenu === true ? styles.show : ''
        }`}
      >
        <Container size={'large'} spacing={'min'}>
          <ExpandedMenu menu={menu} />
        </Container>
      </div>
         */

/**
       * 
       * <Drawer visible={showMiniCart} close={() => setShowMiniCart(false)}>
        <MiniCart />
      </Drawer>
       */
