import React from "react";
import {
  ProductImage,
  ProductPrice,
  ProductTitle,
  TrendingContainer,
  TrendingProductsContainer,
  TrendingTitle,
} from "./styles";
const trendingProducts = [
  {
    id: "polo-nevado",
    title: "Polo Nevado",
    price: "S/. 100.00",
    image: '/products/polo-nevado.jpeg',
  },
  {
    id: "polo-color",
    title: "Polo Color Entero",
    price: "S/. 90.00",
    image: '/products/polo-color.jpeg',
  },
  {
    id: "top-mujer",
    title: "Top",
    price: "S/. 100.00",
    image: '/products/top.jpeg',
  },
  {
    id: "polera-dos-colores",
    title: "Polera Dos Colores",
    price: "S/. 150.00",
    image: '/products/polera-dos-colores.jpeg',
  },
];

export const Trending = () => {
  return (
    <TrendingContainer>
      <TrendingTitle>Tendencias</TrendingTitle>
      {trendingProducts.map((product) => (
        <TrendingProductsContainer key={product.id}>
          <ProductImage src={product.image} alt={product.title} />
          <ProductTitle>{product.title}</ProductTitle>
          <ProductPrice>{product.price}</ProductPrice>
        </TrendingProductsContainer>
      ))}
    </TrendingContainer>
  );
};
