import React from 'react'
import { Header } from "../Header";

interface LayoutProps {
    children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
    return (
        <>
          <Header />

          {children}
          {/* <main
            className={`${styles.main} ${
              disablePaddingBottom === true ? styles.disablePaddingBottom : ''
            }`}
          >
            {children}
          </main> */}
          {/* <Footer /> */}
        </>
      );
}