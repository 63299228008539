import React from 'react';
import { navigate } from 'gatsby';

import { LogoContainer } from './styles';

export const Brand = () => {
  return (
    <LogoContainer
      role={'presentation'}
      onClick={() => navigate('/')}
    >
      <h3>STYLE UP</h3>
    </LogoContainer>
  );
};