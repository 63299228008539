import React from "react";
import {
  EventContainer,
  EventCta,
  EventInfo,
  EventsCategoryTitle,
  EventsContainer,
  EventTitle,
} from "./styles";

const events = [
  {
    id: "bad-bunny",
    image:
      "https://images.bubbleup.com/vipnation/1-default/2-vipnationcom/bad_bunny_1280x1280_1642985367.jpg",
    title: "Bad Bunny - World's Hottest Tour",
    link: "#",
  },
  {
    id: "emilia-lima",
    image:
      "https://www.innewsmusic.com/wp-content/uploads/2022/10/Emilia-Mernes.jpg",
    title: "Emilia Mernes en Lima",
    link: "#",
  },
  {
    id: "morat-lima",
    image:
      "https://cdn.teleticket.com.pe/especiales/morat-per22/images/event-artista-res.jpg",
    title: "Morat World Tour",
    link: "#",
  },
];

export const Events = () => {
  return (
    <EventsContainer>
      <EventsCategoryTitle>Próximos Eventos</EventsCategoryTitle>
      {events.map((event) => (
        <EventContainer key={event.id} image={event.image}>
          <EventInfo>
            <EventTitle>{event.title}</EventTitle>
            <EventCta>
              <a href={event.link}>Ver más</a>
            </EventCta>
          </EventInfo>
        </EventContainer>
      ))}
    </EventsContainer>
  );
};
