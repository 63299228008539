import * as React from "react";
import type { HeadFC } from "gatsby";
import { Layout } from "../components/Layout";
import { Hero } from "../components/Hero";
import { Events } from "../components/Events";
import { Trending } from "../components/Trending";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 16px)",
};
const headingStyles = {
  maxWidth: 780,
  textAlign: "center",
};

const V2Page = () => {
  return (
    <Layout>
      <Hero
        image="https://images.unsplash.com/photo-1569840471095-ffa5de0c4c33?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80"
        title="Eventos con estilo"
        subtitle="Encuentra el mejor merch para tus eventos y luce tu mejor outfit 😎"
        cta={{
          text: "Comprar ahora",
          action: () => {
            alert("Compra ya!");
          },
        }}
      />
      <Events />
      <Trending />
    </Layout>
  );
};

export default V2Page;

export const Head: HeadFC = () => (
  <title>Style Up Store - El mejor outfit para tus eventos</title>
);
