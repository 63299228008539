import React from 'react';
import { Bag, Search } from './icons';


interface IconProps {
    symbol: string
}

export const Icon = ({ symbol }: IconProps) => {
  switch (symbol) {
    case 'bag':
      return <Bag />;
    case 'search':
      return <Search />;
    default:
      return <span>Unknown icon: {symbol}</span>;
  }
}