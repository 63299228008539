import React from "react";
import { HeroContainer, HeroContent, HeroCTA, HeroSubtitle, HeroTitle } from "./styles";

interface HeroProps {
  image: string;
  title?: string;
  subtitle?: string;
  cta?: {
    text: string;
    action: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };
}

export const Hero = ({ title, subtitle, image, cta }: HeroProps) => {
  return (
    <HeroContainer image={image}>
      <HeroContent>
        {title && <HeroTitle>{title}</HeroTitle>}
        {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
        {cta && (
          <HeroCTA
            /*className={`${styles.ctaButton} ${ctaStyle}`}*/
            onClick={cta.action}
          >
            {cta.text}
          </HeroCTA>
        )}
      </HeroContent>
    </HeroContainer>
  );
};
