import styled from "@emotion/styled";

export const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  z-index: 50;
`;

export const HeaderLine = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 48px 16px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 24px 8px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;

  & > * {
    margin-right: 32px;
    cursor: pointer;
    color: #000;
  }
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;


  border: none;
  background-color: unset;
  overflow: visible;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  -webkit-appearance: button;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
