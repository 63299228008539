import styled from "@emotion/styled";

export const HeroContainer = styled.section<{ image: string }>`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 70vh;

  ${({ image }) => `background-image: url(${image});`}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  @media (max-width: 800px) {
    background-position-x: 33%;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  max-width: 80%;
  gap: 40px;
`;

export const HeroTitle = styled.h2`
  font-size: 36px;
  line-height: 48px;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
`;

export const HeroSubtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const HeroCTA = styled.button`
  text-transform: uppercase;
  color: #fff;
  background-color: #524eb7;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 16px;
  border: none;
  outline: none;
  border-radius: 4px;
`;
