import styled from "@emotion/styled";

export const EventsContainer = styled.section`
  padding: 16px 8px;
`;

export const EventsCategoryTitle = styled.h3`
  font-family: "Fruktur";
  color: #524eb7;
  font-size: 24px;
`;

export const EventContainer = styled.div<{ image: string }>`
  position: relative;
  min-height: 330px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 16px auto;

  ${({ image }) => `background-image: url(${image});`}

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const EventInfo = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #fff;
`;

export const EventTitle = styled.h3`
  margin: 8px auto;
`;

export const EventCta = styled.a`
  margin: 0;

  a {
    color: #fff;
  }
`;
