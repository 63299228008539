import React from 'react';

export const Bag = () => (
  <svg
    width={18}
    height={20}
    fill="none"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
  >
    <path
      d="m2.202 8.52-.497-.056.497.055Zm-1.037 9.333.496.055-.496-.055Zm15.67 0-.497.055.497-.055Zm-1.037-9.334.497-.055-.497.055ZM1.705 8.464.668 17.798l.993.11 1.037-9.333-.993-.11Zm.785 11.37h13.02v-1H2.49v1Zm14.842-2.036-1.037-9.334-.994.11 1.037 9.334.994-.11ZM14.473 6.833H3.527v1h10.946v-1Zm1.822 1.631a1.833 1.833 0 0 0-1.822-1.63v1c.425 0 .782.319.829.74l.993-.11Zm-.785 11.37c1.093 0 1.943-.95 1.822-2.036l-.994.11a.833.833 0 0 1-.828.925v1ZM.668 17.797a1.833 1.833 0 0 0 1.822 2.035v-1a.833.833 0 0 1-.829-.925l-.993-.11Zm2.03-9.223a.833.833 0 0 1 .829-.742v-1c-.934 0-1.72.703-1.822 1.631l.993.11ZM5.5 5.333v-.666h-1v.666h1Zm7-.666v.666h1v-.666h-1ZM9 1.167a3.5 3.5 0 0 1 3.5 3.5h1A4.5 4.5 0 0 0 9 .167v1Zm-3.5 3.5a3.5 3.5 0 0 1 3.5-3.5v-1a4.5 4.5 0 0 0-4.5 4.5h1Z"
      fill="currentColor"
    />
  </svg>
);