import styled from "@emotion/styled";

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  h3 {
    margin: 0;
    font-family: 'Fruktur';
    color: #524eb7;
  }
`;